import React from "react";
import { Link } from "react-router-dom";
import "moment/locale/fr";

import { monthInLetter } from "utils/Utils";

const TeamPlanningHeaderMobile = (props) => {
    const { day } = props;

    const changeWeek = (movement) => {
        const targetDay = day.clone()
        if (movement === "previous") {
            targetDay.subtract(1, "week");
        } else if (movement === "next") {
            targetDay.add(1, "week");
        }

        return `/backend/team-planning-by-week/${targetDay.isoWeekYear()}/${targetDay.isoWeek()}`;
    };

    return (
        <div className="planning-week-selector-container">
            <Link to={changeWeek("previous")} className="planning-week-selector-btn"><i className="fa fa-chevron-left"></i></Link>
            <div className="planning-week-selector">Semaine du {day.format('DD')} {monthInLetter(day.format('M'))}</div>
            <Link to={changeWeek("next")} className="planning-week-selector-btn"><i className="fa fa-chevron-right"></i></Link>
        </div>
    );
};

export default TeamPlanningHeaderMobile;
