import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "utils/Utils";
import * as SettingsActions from "actions/Settings";
import "./ParametersContainer.scss"
import PageHeader from "../common/PageHeader";
import SlidePageTransitionPortal from "../common/SlidePageTransitionPortal";
import ParametersContent from "./ParametersContent";
import {logout} from "utils/Utils";
import {Link} from "react-router-dom";
import LogoutButton from "../common/LogoutButton";
import useDisplayInstallProcedure from "../../hooks/useDisplayInstallProcedure";

export const REMOTE_CONTENT = "remote"
export const ACCOUNT_CONTENT = "account"
export const NOTIFICATIONS_CONTENT = "notifications"
export const RUNAS_CONTENT = "runas"
export const INSTALL_CONTENT = "install"

const VALID_ANCHORS = [
    REMOTE_CONTENT,
    ACCOUNT_CONTENT,
    NOTIFICATIONS_CONTENT,
    RUNAS_CONTENT,
    INSTALL_CONTENT,
];

const ParametersContainer = (props) => {
    const remoteDays = useSelector((state) => state.settings.entities.remoteDaysConfig);
    const me = useSelector((state) => state.user.me);
    const isMobile = useSelector((state) => state.common.isMobile)
    const dispatch = useDispatch();
    const [activeContent, setActiveContent] = useState();
    const {personType} = props
    const [anchor, setAnchor] = useState("");

    useEffect(() => {
        const handleHashChange = () => {
            setAnchor(window.location.hash.replace('#', '')); // Met à jour l'ancre
        };

        // Initialisation de l'ancre
        handleHashChange();

        // Écoute les changements d'ancre
        window.addEventListener("hashchange", handleHashChange);

        // Nettoyage
        return () => {
            window.removeEventListener("hashchange", handleHashChange);
        };
    }, []);


    useEffect(() => {
        if (VALID_ANCHORS.includes(anchor)) {
            handleClick(anchor)
        }
    }, [anchor])

    const [showPortal, setShowPortal] = useState(false)

    const displayInstallProcedure = useDisplayInstallProcedure()

    const patchRemoteDaysConfig = () => {

    }

    const buildCraCategory = () => {
        const subCategories = []

        if (remoteDays) {
            subCategories.push({
                title: personType === "contractor" ? "Travail à distance" : "Télétravail",
                subTitle: "Définissez ici vos jours de " + (personType === "contractor" ? "travail à distance" : "télétravail") + " récurrents",
                cardTitle: "Mes jours de " + (personType === "contractor" ? "travail à distance" : "télétravail") + " récurrents",
                dataType: "checkboxList",
                data: remoteDays,
                patchFunction: patchRemoteDaysConfig
            })
        }

        return {
            categoryTitle: "CRA",
            subCategories: subCategories
        }
    }

    const handleClose = () => {
        setShowPortal(false);
    };

    const handleClick = (contentType) => {
        setActiveContent(contentType)
        setShowPortal(true)
    }

    const [craCategory, setCraCategory] = useState({})

    const getSectionTitle = (sectionType) => {
        switch (sectionType) {
            case REMOTE_CONTENT:
                return personType === "contractor" ? "Travail à distance" : "Télétravail"
            case  NOTIFICATIONS_CONTENT:
                return "Notifications"
            case ACCOUNT_CONTENT:
                return "Mon Compte"
            case RUNAS_CONTENT:
                return "Run As"
            case INSTALL_CONTENT:
                return "Installer l'application"
            default:
                return ""
        }
    }

    useEffect(() => {
        dispatch(SettingsActions.fetchRemoteDaysConfig());
    }, []);

    useEffect(() => {
        if (!isEmpty(remoteDays)) {
            setCraCategory(buildCraCategory())
        }
    }, [remoteDays]);


    return <div id="page-container">
        {/*<div className="container">*/}
        <div className="container">
            <PageHeader title="Paramètres" type="parameters"/>
            <div className="parameters-container">
                <div className="parameters-menu">
                    <Link
                        to={'/parameters#' + REMOTE_CONTENT}
                        className={"parameters-menu-entry" + (activeContent === REMOTE_CONTENT ? " parameters-menu-entry-active" : "")}>
                        {/*// onClick={() => handleClick(REMOTE_CONTENT)}>*/}
                        <i className="fal fa-home"></i> {getSectionTitle(REMOTE_CONTENT)}

                    </Link>

                    {('serviceWorker' in navigator) && <Link to={'/parameters#' + NOTIFICATIONS_CONTENT}
                                                             className={"parameters-menu-entry" + (activeContent === NOTIFICATIONS_CONTENT ? " parameters-menu-entry-active" : "")}
                                                             onClick={() => setAnchor(NOTIFICATIONS_CONTENT)}>
                        <i className="fal fa-bell"></i> {getSectionTitle(NOTIFICATIONS_CONTENT)}
                    </Link>}
                    {me.type !== "internal" && <Link to={'/parameters#' + ACCOUNT_CONTENT}
                                                     className={"parameters-menu-entry" + (activeContent === ACCOUNT_CONTENT ? " parameters-menu-entry-active" : "")}
                                                     onClick={() => setAnchor(ACCOUNT_CONTENT)}>
                        <i className="fal fa-user-cog"></i> {getSectionTitle(ACCOUNT_CONTENT)}
                    </Link>}

                    {me.canRunAs() && isMobile && (<Link to={'/parameters#' + RUNAS_CONTENT}
                                                         className={"parameters-menu-entry" + (activeContent === RUNAS_CONTENT ? " parameters-menu-entry-active" : "")}
                                                         onClick={() => setAnchor(RUNAS_CONTENT)}>
                        <i className="fal fa-user-ninja"></i> {getSectionTitle(RUNAS_CONTENT)}
                    </Link>)}

                    {displayInstallProcedure && (<Link to={'/parameters#' + INSTALL_CONTENT}
                                                       className={"parameters-menu-entry" + (activeContent === INSTALL_CONTENT ? " parameters-menu-entry-active" : "")}
                                                       onClick={() => setAnchor(INSTALL_CONTENT)}>
                        <i className="fas fa-mobile-alt"></i> {getSectionTitle(INSTALL_CONTENT)}
                    </Link>)}

                    <LogoutButton className="parameters-menu-entry"/>
                </div>
                <div className="parameters-content">
                    {isMobile ? <SlidePageTransitionPortal show={showPortal} handleClose={handleClose}
                                                           className={"parameters-page"}>
                            {!isEmpty(craCategory) && <>
                                <ParametersContent activeContent={activeContent} craCategory={craCategory}
                                                   handleClose={handleClose}/>
                            </>
                            }
                        </SlidePageTransitionPortal> :
                        <ParametersContent activeContent={activeContent} craCategory={craCategory}/>}
                </div>
            </div>
        </div>
    </div>
}

export default ParametersContainer
