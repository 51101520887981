import React, {useEffect, useState} from "react";
import "./NotificationsContainer.scss"

import PageHeader from "../common/PageHeader";
import moment from "moment";
import Slider from "../TimesheetContainer/Calendar/Slider";
import {
    disabableNotitificationUntil, enableNotitification, enableNotitificationFor,
    getNotificationConfig, setNotitificationHours,
    toggleEnabledNotitification
} from "../../utils/NotificationConfig";
import {changeOverlayContent} from "../../events/OverlayEvents";
import NotificationStatus from "./NotificationStatus";

const NotificationsContainer = (props) => {
    const handleClose = props.handleClose;

    const hasNotification = 'Notification' in window
    // const hasNotification = false

    const [notificationConfig, setNotificationConfig] = useState(getNotificationConfig());

    if ('permissions' in navigator) {
        navigator.permissions.query({name: 'notifications'}).then(function (notificationPerm) {
            notificationPerm.onchange = function () {
                const interval = setInterval(async () => {
                    const config = await getNotificationConfig()
                    setNotificationConfig(config)
                }, 100)
                setTimeout(() => {
                    clearInterval(interval)
                }, 5000)
            };
        });
    }

    useEffect(() => {
        if (hasNotification && Notification.permission === 'granted' && notificationConfig.enabled === null) {
            setNotificationConfig(enableNotitification())
            setNotificationConfig(enableNotitificationFor('fullday'))
        }
    }, [])

    const openPicker = () => {
        if (!notificationConfig.enabled) {
            document.getElementById('disable-notif-date-picker').showPicker()
        }
    }

    const handleDateChange = (e) => {
        const value = e.target.value
        setNotificationConfig(disabableNotitificationUntil(value === "" ? null : value))
    }
    const handleToggleEnabled = (e) => {
        setNotificationConfig(toggleEnabledNotitification())
    }

    const handleFullDay = () => {
        setNotificationConfig(enableNotitificationFor('fullday'))
    }

    const handleCustom = () => {
        const handleSubmit = (e) => {
            const formData = new FormData(e.target)
            const data = {
                start: formData.get('start'),
                end: formData.get('end'),
            }
            changeOverlayContent(null)
            setNotificationConfig(setNotitificationHours(data))
        }
        changeOverlayContent(<form className="notif-hours-form-container" onSubmit={handleSubmit}>
            <div className="parameters-card">
                <p>Définissez les heures entre lesquelles vous souhaitez recevoir des notifications.</p>
                <div className="notif-hours-form">
                    <label htmlFor="start">Début</label><input id="start" name="start" type="time"
                                                               defaultValue={notificationConfig.start} required/>
                    <label htmlFor="end">Fin</label><input id="end" name="end" type="time"
                                                           defaultValue={notificationConfig.end} required/>
                </div>
                <div className="lb-confirm-send-actions">
                    <div onClick={() => changeOverlayContent(null)} className="lb-confirm-send-cancel">Annuler</div>
                    <button className="lb-confirm-send-confirm">Valider</button>
                </div>
            </div>
        </form>)
    }

    return <div className="parameters-category-container">
        <div className="parameters-subcategory-container">
            <PageHeader title={"Notifications"} action={handleClose} type={"parameters"}/>
            <div className="parameters-subcategory-subtitle">
                Configurez ici vos préférences relatives aux notifications.
            </div>
            <NotificationStatus/>
            {hasNotification ? <>
                    <div className="parameters-card-title">Recevoir les notifications</div>
                    <div className="parameters-card">
                        <div className="config-line" onClick={handleFullDay}>Toute la
                            journée {notificationConfig.enabledFor === 'fullday' && <i className="fal fa-check"></i>}</div>
                        <div className="config-separator"></div>
                        <div className="config-line"
                             onClick={handleCustom}>Programmé {notificationConfig.enabledFor === 'custom' &&
                            <i className="fal fa-check"></i>}</div>
                        <div
                            className="config-value">{notificationConfig.start && notificationConfig.end ? [notificationConfig.start, notificationConfig.end].join(' - ') : 'Définissez vos heures de réception'}</div>
                    </div>
                    <div className="parameters-card-description">Les notifications seront reçues uniquement pendant les
                        plages
                        horaires sélectionnées.
                    </div>
                    <div className="parameters-separator"></div>
                    <div className="parameters-card-title">Désactiver les notifications</div>
                    <div className="parameters-card">
                        <div className="disable-notif" onClick={handleToggleEnabled}>
                            <Slider label="Désactiver" value={!notificationConfig.enabled}/>
                        </div>

                        <div className="config-separator"></div>
                        <div onClick={openPicker}
                             className={"disable-until " + (notificationConfig.enabled ? "config-disabled" : "")}>
                            Pour combien de temps ?
                            <input onInput={handleDateChange} required={false} id="disable-notif-date-picker" type="date"
                                   min={moment().format("YYYY-MM-DD")}/>
                            <div
                                className="config-value">{notificationConfig.disabledUntil ? "Jusqu'au " + moment(notificationConfig.disabledUntil).parseZone().format("DD/MM/YYYY") : "Indéfiniment"}</div>
                        </div>
                    </div>
                    <div className="parameters-card-description">Les notifications seront désactivées indéfiniment ou
                        jusqu'à la
                        date selectionnée (incluse).
                    </div>
                </> :
                <div className="config-line">Pour recevoir les notifications, nous vous suggérons d'installer
                    l'application
                </div>}
        </div>
    </div>
}

export default NotificationsContainer
