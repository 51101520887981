import React, {useEffect, useState} from "react";

const GlobalProcedure = () => {
    return <div>
        <h3>Chrome, Edge, Opera, Brave (Windows ou macOS)</h3>
        <ol>
            <li>Ouvrez votre navigateur (Chrome, Edge, Opera ou Brave).</li>
            <li>Accédez à https://mysynchrone.fr</li>
            <li>Dans la barre d'adresse, recherchez l'icône Installer ou Télécharger (un petit ordinateur avec une
                flèche vers
                le bas) à droite.
            </li>
            <li>Cliquez sur cette icône, puis sélectionnez Installer.</li>
            <li>L'application sera installée et accessible depuis le menu démarrer ou le dock.</li>
        </ol>


        <h3>Safari (macOS)</h3>
        <ol>
            <li>Ouvrez Safari.</li>
            <li>Rendez-vous sur https://mysynchrone.fr</li>
            <li>Cliquez sur le menu Fichier, puis sur Ajouter à la barre latérale ou Créer un raccourci (selon la
                version de
                Safari).
            </li>
            <li>L'application sera ajoutée en tant que raccourci dans Safari.</li>
        </ol>

    </div>
}

export default GlobalProcedure;
