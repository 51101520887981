import React, {useEffect, useState} from "react";
import {getBrowserInfo, getOSInfo} from "../../utils/Browser";
import AndroidProcedure from "./AndroidProcedure";
import AppleProcedure from "./AppleProcedure";
import GlobalProcedure from "./GlobalProcedure";

const InstallProcedure = () => {
    const osInfo = getOSInfo();
    const browserInfo = getBrowserInfo();

    const Procedure = () => {
        switch (osInfo.osName) {
            case "Android" :
                return <AndroidProcedure/>
            case "iOS" :
                return <AppleProcedure/>
            default:
                return <GlobalProcedure/>
        }
    }

    return <>
        <div className="parameters-card-title">Installer l'application</div>
        <div className="parameters-card">
            <div className="install-app">
                <Procedure/>
            </div>
        </div>
    </>
}

export default InstallProcedure
